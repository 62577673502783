import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import Theme from "../lib/Theme"
import { CloudinaryImage } from "./CloudinaryImage"
import { Heading } from "./Heading"

export interface TipProps {
  id: string
  mainImage: { url: string; alt: string }
  title: string
}

export const TipCard = ({ id, title, mainImage }: TipProps) => {
  return (
    <Wrap
      to={`/${id}/`}
      data-track-content
      data-content-name="Tip"
      data-content-piece={title}
    >
      <PostImage
        publicId={mainImage.url}
        alt={mainImage.alt}
        width={400}
        height={250}
        crop="fill"
      />

      <Title as="div">{title}</Title>
    </Wrap>
  )
}

const PostImage = styled(CloudinaryImage)`
  transition: transform 1.2s ease-in-out;
  object-fit: contain;
  display: block;
  width: 100%;
`

const Title = styled(Heading)`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1rem;
  margin: 0;
  color: #fff;
  display: block;

  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 90%
  );
`

const Wrap = styled(Link)`
  display: block;
  position: relative;
  background: ${Theme.Colors.purple.s100};
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    ${PostImage} {
      transform: scale(1.05) rotate(-0.5deg);
    }
  }
`
