import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { HeadingBlock } from "../components/HeadingBlock"
import { Layout } from "../components/Layout"
import { Row } from "../components/Row"
import { SEO } from "../components/SEO"
import { TipCard } from "../components/TipCard"
import Theme from "../lib/Theme"

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  margin: 3rem 0;

  ${Theme.Mediaquery.medium.min} {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
`

interface AllTip {
  id: string
  mainImage: string
  title: string
}

const TipsPage = ({ data }: Kirby.Data<{ allTip: AllTip }>) => {
  const tips = data.kirby.allTip

  return (
    <Layout>
      <SEO
        title="Tips voor een betere band met je paard"
        description="Interessante tips van Schriktraining Deluxe die bijdragen aan de fysieke en emotionele gezondheid van je paard."
      />

      <Row first>
        <HeadingBlock
          title="Tips voor een betere band met je paard"
          subtitle="Verrijk je kennis"
          titleAs="h1"
        >
          <p>
            Schriktraining is een goede manier om je paard en jezelf stoerder te
            maken. Maar er zijn nog veel meer manieren om aan je band met je
            paard te werken en hem te ondersteunen om zelfverzekerd door het
            leven te gaan. In deze artikelen lees je allerlei interessante tips
            die bijdragen aan de fysieke en emotionele gezondheid van je paard.
          </p>
        </HeadingBlock>
      </Row>

      <Row>
        <Grid>
          {tips.map((tip) => (
            <TipCard key={tip.id} {...tip} />
          ))}
        </Grid>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query {
    kirby {
      allTip(sortBy: ["date", "DESC"]) {
        id
        mainImage {
          url
          alt
        }
        title
      }
    }
  }
`

export default TipsPage
